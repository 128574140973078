<template>
    <div>
        <div class="header-title flex flex-y" :class="{fixed:fixed}">
            <div v-if="isReturn" class="header-left">
                <div class="header-box flex flex-y flex-x" v-if="!notoken" @click="returnFun">
                    <i class="iconfont">&#xe62d;</i>
                </div>
            </div>
            <div class="header-left" v-else>
                <div v-if="report">
                    <div @click="operateFun">操作</div>
                    <ul class="report-ul" v-if="show">
                        <li @click="loginFun">注册/登录</li>
                        <li @click="sendFun">发送报告</li>
                        <li class="dim" v-if="noPrint">打印报告</li>
                        <li v-else @click="print">打印报告</li>
                        <li @click="prescribeFun" v-if="open">开方报告</li>
                        <li @click="historyFun">历史报告</li>
                    </ul>
                </div>
            </div>
            <div class="header-centre flex-1">{{title}}</div>
            <div v-if="isClose" class="header-right flex flex-y" @click="closeFun">
                <div class="flex-1"></div>
                <div class="header-cross">
                    <van-icon name="cross" />关闭
                </div>
            </div>
            <div class="header-right" v-else>
                <div v-if="rightName" @click="rightFun" class="right-class">
                    {{rightName}}
                </div>
            </div>
        </div>
        <div class="header-fixed" v-if="fixed"></div>
    </div>
</template>
<script>
import { mapActions , mapMutations } from "vuex";
import { Toast ,Notify } from 'vant';
export default {
  name: 'vueHeader',
  data(){
      return{
          isClose:false,
          isReturn:false,
          show:false,
          open:false,
          noPrint:true
      }
  },
  props: ["title",'report','fixed','rightName','rightFun','noClose','returns','notoken','isLoginFun'],
  mounted(){
     this.device();
     let flow = JSON.parse(sessionStorage.getItem('flow'));
     let device = sessionStorage.getItem('device');
     if(device=='windows'){
         this.noPrint = false;
     }
     if(flow && (flow.flow_info.flow_step==5||flow.flow_info.flow_step==6)){
         this.open = true;
     }
  },
  methods:{
      device(){
          let device = sessionStorage.getItem('device');
          if(device=='android' || device=='windows'){
              this.isClose = true;
              this.isReturn = false;
          }else{
              this.isClose = false;
              this.isReturn = true;
          }
      },
      operateFun(){
          this.show = !this.show;
      },
      //打印
      print(){
          let device = sessionStorage.getItem('device');
          if(device=='windows'){
              let deviceNameObj;
              let _this = this;
              window.ipcRenderer.send("getPrinterList"); // 获取打印机列表
              window.ipcRenderer.on("printerList", function (e, data) {
                  console.log(data);
                  if (data.length <= 0) {   //连接失败
                    Toast.fail('连接失败，找不到打印机 请稍后再试');
                  }else {   //连接成功，开始打印
                    Toast.fail('连接成功，开始打印');
                    deviceNameObj = data.find((item) => item.isDefault);
                    setTimeout(() => {
                        _this.startPrint(deviceNameObj?deviceNameObj.name:'');
                    }, 2500);
                  }
              });
          }
      },
      //打印中
      startPrint(name){
          let device = sessionStorage.getItem('device');
          if(device=='windows'){
              window.ipcRenderer.send("print");
              window.ipcRenderer.on("printRes", (val) => {
                  if (val) {
                      console.log(name);
                      Toast.fail(`正在打印中,请稍等`);
                  }else{
                      Toast.fail(`打印机出错 请稍后再试`);
                  }
              })
          }else{
              window.print();
          }
      },
      sendFun(){
          if(sessionStorage.token){
            //发送模版消息
            // this.axiosSend({
            //     data:{
            //         token:this.$route.params.id
            //     }
            // })
            this.changeFun({
                key:'send',
                value:true
            })
            let equipment = JSON.parse(sessionStorage.getItem('equipment'));
            if(equipment && equipment.send_template_select==1){
                this.sendMain({
                    equipment_code:equipment.equipment_code
                });
            }
          }else{
            //登录
            this.changeFun({
                key:'login',
                value:true
            })
          }
      },
      //开方二维码
      prescribeFun(){
          if(sessionStorage.token){
                this.changeFun({
                    key:'doctor',
                    value:true
                })
          }else{
                this.changeFun({
                    key:'prescribe',
                    value:true
                })
          }
      },
      loginFun(){
          if(!sessionStorage.token){
            //登录
            this.changeFun({
                key:'history',
                value:true
            });
            this.$props.isLoginFun(true);
          }else{
              Notify('您已登录，请勿重复登录！');
          }
      },
      historyFun(){
          if(sessionStorage.token){
            this.$router.push({
                name:'reportList'
            });
          }else{
            //登录
            this.changeFun({
                key:'history',
                value:true
            });
            this.$props.isLoginFun();
          }
      },
      returnFun(){
          if(this.$props.returns){
              this.$props.returns();
          }else{
              this.$router.back('-1');
          }
      },
      closeFun(){
          if(this.$props.noClose){
                this.$router.back('-1');
          }else{
                let equipment = sessionStorage.getItem('equipment');
                let device = sessionStorage.getItem('device');

                sessionStorage.removeItem('faceID');
                sessionStorage.removeItem('tongueID');

                // sessionStorage.removeItem('flow');
                // sessionStorage.removeItem('equipment');

                sessionStorage.removeItem('token');
                if(device=='windows'){
                    this.$router.push({
                        name:'device'
                    });
                }else{
                    this.$router.push({
                        name:'device',
                        query: {
                            mac:JSON.parse(equipment).mac
                        }
                    });
                }
          }
      },
      ...mapMutations ("report", ["changeFun"]),
      ...mapActions ("report", ["axiosSend","sendMain"])
  }
}
</script>

<style lang="scss" scoped> 
    @import './header'; 
</style>